import { collection, doc, getDocs, updateDoc, query, where } from "firebase/firestore";
import { database } from "./firebase";

export const getLocations = async () => {
  const docRef = query(
    collection(database, "locations"),
    where("access", "==", "public")
  );
  
  const snapshot = await getDocs(docRef);
  return snapshot.docs.map((doc) => ({ ...doc.data(), documentId: doc.id }));
};

export const updateCompliant = async (documentId, data) => {
  await updateDoc(
    doc(database, "complaints", documentId),
    {
      assets: data,
    },
    { merge: true }
  );
  return documentId;
};
