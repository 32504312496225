import React, { useEffect, useState } from "react";
import InputBox from "../../Components/InputBox/InputBox";
import Button from "../../Components/Button/Button";
import Modal from "../../Components/Modal/Modal";
import CropperModal from "../../Components/CropperModal/CropperModal";
import {
  AddIcon,
  ArrowIcon,
  CallIcon,
  CloseIcon,
  EditIcon,
  LanguageIcon,
  TickIconWithCircle,
} from "../../Assets/assets";
import OTPInputBox from "../../Components/OTPInputBox/OTPInputBox";
import {
  logout,
  otpRequest,
  otpValidate,
  reCaptchaVerifier,
  signInWithAuthCredentials,
} from "../../Services/authentication";
import { isValidArray, isValidObject } from "../../Services/validators";
import "./RaiseComplaint.css";
import chennaiCorporationLogo from "../../Assets/chennaiCorporationLogo.png";
import { useLanguage } from "../../Components/LanguageContext/LanguageContext";
import { countryData } from "../../utils/CallCodes";
import PhoneNumberBox from "../../Components/PhoneNumberBox/PhoneNumberBox";
import Select from "../../Components/Select/Select";
import { raiseComplaint } from "../../Services/api";
import { errorHandler } from "../../Services/error";

export default function RaiseComplaint(props) {
  const [file, setFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState([]);
  const [verificationId, setVerificationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sendOTPLoading, setSendOTPLoading] = useState(false);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [authCredentials, setAuthCredentials] = useState(null);
  const [showDataModal, setShowDataModal] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [isValidFullName, setIsValidFullName] = useState(true);
  const [selectedLocationData, setSelectedLocationData] = useState(null);
  const [countryInfo, setCountryInfo] = useState({
    selected: { name: "India", flag: "🇮🇳", code: "IN", dial_code: "+91" },
    show: false,
    searchKey: null,
  });
  const [locationSearchModal, setLocationSearchModal] = useState(false);
  const { selectedLanguage, setSelectedLanguage, languageData } = useLanguage();
  const locationId = window.location.pathname.split("/")[1];

  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    issue: [],
    description: "",
    OTP: "",
  });

  useEffect(() => {
    window.reCaptchaVerifier = reCaptchaVerifier();
  }, []);

  useEffect(() => {
    if (isValidArray(props.locations)) {
      setSelectedLocationData(
        props.locations.find((data) => locationId === data.documentId)
      );
    } else if (selectedLocationData !== null) {
      setSelectedLocationData(null);
    }
    // eslint-disable-next-line
  }, [locationId, props.locations]);

  const handleLogout = async () => {
    setLoading(true);
    await logout();
    setLoading(false);
  };

  useEffect(() => {
    if (props.isAuth) {
      handleLogout();
    }
    // eslint-disable-next-line
  }, []);

  const handleAPi = async () => {
    try {
      if (!locationId) {
        return props.setStatus({
          code: "custom",
          message: languageData.someThingWrong,
        });
      }
      setLoading(true);
      if (formData?.issue?.length > 0 && props.accessToken) {
        for (let index = 0; index < formData.issue.length; index++) {
          let data = new FormData();

          if (isValidArray(croppedImage)) {
            for (let i = 0; i < croppedImage.length; i++) {
              data.append("files", croppedImage[i]);
            }
          }
          data.append("locationId", locationId);
          data.append("category", formData.issue?.[index]);
          data.append(
            "issuedBy",
            JSON.stringify({
              issuedAt: +new Date(),
              type: "public",
              userDetails: {
                name: formData.fullName,
                phoneNumber: `${countryInfo.selected.dial_code}${formData.phoneNumber}`,
              },
            })
          );
          data.append(
            "status",
            JSON.stringify({
              currentStatus: "OPEN",
              updatedAt: +new Date(),
            })
          );

          await raiseComplaint(data, props.accessToken);
        }

        props.setStatus({
          code: null,
          message: languageData.compliantSubmitted,
        });
        handleLogout();
        setShowDataModal(false);
        setLoading(false);
        setFormData({
          fullName: "",
          phoneNumber: "",
          issue: "",
          description: "",
          OTP: "",
        });
        setCroppedImage([]);
      } else {
        setLoading(false);
        setShowDataModal(false);
        props.setStatus({
          code: "error",
          message: languageData.invalidIssue,
        });
      }
    } catch (error) {
      const data = errorHandler(error);
      props.setStatus({
        code: "error",
        message: data?.message?.[selectedLanguage],
      });
      console.error("error", data);
      setLoading(false);
      setShowDataModal(false);
    }
  };

  useEffect(() => {
    try {
      if (isValidObject(authCredentials) && props.isAuth) {
        handleAPi();
      }
    } catch (error) {
      props.setStatus({
        code: "error",
        message: languageData.tryLater,
      });
    }
    // eslint-disable-next-line
  }, [authCredentials, props.isAuth]);

  const otpRequestHandler = async () => {
    try {
      setSendOTPLoading(true);
      setVerificationId(
        await otpRequest(
          `${countryInfo.selected.dial_code}${formData.phoneNumber}`
        )
      );
      setSendOTPLoading(false);
    } catch (error) {
      setSendOTPLoading(false);
      const data = errorHandler(error);
      console.log(data, "datadata");
      props.setStatus({
        code: "error",
        message: data?.message?.[selectedLanguage],
      });
    }
  };

  const handleValidateOTP = async () => {
    setLoading(true);
    if (verificationId) {
      try {
        const response = await otpValidate(formData?.OTP, verificationId);
        if (isValidObject(response)) {
          setAuthCredentials(await signInWithAuthCredentials(response));
        }
      } catch (error) {
        const data = errorHandler(error);
        props.setStatus({
          code: "error",
          message: data?.message?.[selectedLanguage],
        });
      }
    }
    setLoading(false);
  };

  const handleDisable = () => {
    if (isValidString(formData.fullName)) {
      if (formData.OTP.length === 6 && isValidFullName) {
        return false;
      } else {
        return true;
      }
    } else {
      if (formData.OTP.length === 6 && !formData.fullName) {
        return false;
      } else {
        return true;
      }
    }
  };
  console.log(selectedLanguage, "selectedLanguage");

  return (
    <>
      <div className="max-width-767px inherit-parent-width inherit-parent-height">
        <div className="height-64px background-color-primary flex-justify-content-space-between flex-align-items-center padding-bottom-large padding-top-large padding-left-larger padding-right-larger ">
          <img
            src={chennaiCorporationLogo}
            alt="chennai-corporation-logo"
            width="30px"
          />
          {isValidObject(selectedLocationData) && (
            <>
              <div
                className="remaining-header-width padding-left-large flex-direction-column font-color-white inherit-parent-height"
                data-cy={`header-${selectedLocationData?.location}`}
              >
                <div className="font-size-medium font-family-RHD-medium white-space-nowrap text-overflow-ellipsis">
                  {`${selectedLocationData?.PCTNo} : ${selectedLocationData?.location}`}
                </div>
                <div className="font-size-small font-family-RHD-regular ">
                  Ward: {selectedLocationData?.ward}, Zone:
                  {selectedLocationData?.zone}
                </div>
              </div>
              <div
                className=" display-flex flex-align-items-center"
                onClick={() => {
                  setLocationSearchModal(true);
                }}
                data-cy={"location-edit-button"}
              >
                <EditIcon width={18} height={19} />
              </div>
              <div
                className=" display-flex padding-left-large flex-align-items-center"
                onClick={() => {
                  setShowLanguageModal(true);
                }}
                data-cy={"language-button"}
              >
                <LanguageIcon width={18} height={19} />
              </div>
            </>
          )}
        </div>

        <div className="padding-larger parent-height-sub-64px">
          <form
            className="inherit-parent-height display-flex flex-direction-column flex-justify-content-space-between"
            onChange={(event) => {
              if (event.target.files) {
                if (
                  ["image/png", "image/jpeg", "image/jpg"].includes(
                    event.target.files[0]?.type
                  ) === false
                ) {
                  return props.setStatus({
                    code: "error",
                    message: languageData.invalidFile,
                  });
                }

                if (
                  ["image/png", "image/jpeg", "image/jpg"].includes(
                    event.target.files[0]?.type
                  ) === true &&
                  typeof event.target.files[0]?.size === "number"
                ) {
                  setFile(event.target.files[0]);
                }
              } else {
                setFormData({
                  ...formData,
                  [event.target.name]: event.target.value,
                });
              }
            }}
            onSubmit={(event) => {
              event.preventDefault();
              setShowDataModal(true);
            }}
          >
            <div>
              <div className="font-size-medium text-align-center ">
                {languageData?.title}
              </div>
              <div className="margin-top-default">
                <Select
                  chips={formData?.issue}
                  clearChips={() => {
                    setFormData({
                      ...formData,
                      issue: [],
                    });
                  }}
                  modalTitle={languageData.chooseCategory}
                  multiSelect={true}
                  name="category"
                  label={languageData.category}
                  onChipAdded={(data) => {
                    setFormData({
                      ...formData,
                      issue: [...data],
                    });
                  }}
                  languageData={languageData}
                  data-cy="category-chip-input-box"
                  options={languageData.issues}
                />
                {/* <InputBox
                  name="description"
                  type="text"
                  removeResponsive={true}
                  label={languageData.description}
                  className="margin-top-large"
                  value={formData.description}
                /> */}

                <div className="font-size-small padding-top-large font-family-RHD-medium padding-bottom-default">
                  {languageData.provideImage}
                </div>
                <div className="display-flex height-fit-content flex-wrap">
                  {isValidArray(croppedImage) &&
                    croppedImage.map((data, index) => (
                      <div
                        key={index}
                        className="margin-right-large position-relative"
                        data-cy="uploaded-image-thumbnail"
                      >
                        <div
                          style={{ height: "16px", width: "16px" }}
                          onClick={() => {
                            setCroppedImage(
                              croppedImage?.filter(
                                (value) => value.name !== data.name
                              )
                            );
                            setFile(null);
                          }}
                          data-cy="remove-uploaded-image-button"
                          className="background-color-grey width-fit-content border-radius-100-percentage flex-align-items-center flex-justify-content-center position-absolute right-0 opacity-60-percent"
                        >
                          <CloseIcon height={10} width={10} color="#404040" />
                        </div>
                        <img
                          className="border-radius-default"
                          height={60}
                          width={60}
                          src={data && URL.createObjectURL(data)}
                          alt="data"
                        />
                      </div>
                    ))}
                  <label style={{ height: "60px", width: "60px" }}>
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      name="fileUploadInput"
                      onClick={(event) => {
                        event.target.value = "";
                      }}
                      className="display-none"
                    />
                    {(!isValidArray(croppedImage) ||
                      (isValidArray(croppedImage) &&
                        croppedImage.length <= 4)) && (
                      <div
                        className="background-color-quaternary inherit-parent-height inherit-parent-width display-flex flex-justify-content-center flex-align-items-center border-radius-default"
                        data-cy="file-upload-icon"
                      >
                        <AddIcon />
                      </div>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div className="flex-center-children-vertically margin-vertical-large ">
                <hr
                  style={{ width: "100%" }}
                  className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                />
                <span className=" margin-horizontal-default display-inline-block font-color-secondary font-size-small">
                  OR
                </span>
                <hr
                  style={{ width: "100%" }}
                  className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                />
              </div>
              <div className="inherit-parent-width flex-justify-content-center display-flex flex-direction-column flex-align-items-center">
                <div className="padding-bottom-large">
                  {languageData.contactUs}
                </div>
                <a href="tel:08071279929">
                  <div
                    className="cursor-pointer display-flex
                  flex-justify-content-center border-bottom-1px
                  flex-align-items-center padding-bottom-smaller"
                  >
                    <CallIcon />
                    <div className="padding-left-default">080712 79929</div>
                  </div>
                </a>
                <div className="text-align-center padding-top-default padding-bottom-default">
                  {languageData.sharePCT} &nbsp;
                  <span className="font-family-RHD-medium">
                    {selectedLocationData?.PCTNo}
                  </span>
                  &nbsp; {languageData.assistYou}
                </div>
              </div>
            </div>
            <Button
              text={languageData.continue}
              data-cy={"continue-button"}
              type="submit"
              boxShadow={false}
              loading={loading}
              disabled={formData.issue.length > 0 ? false : true}
            />
          </form>
        </div>
        <Modal
          show={file !== null}
          canIgnore={true}
          onClose={() => {
            setFile(null);
          }}
          width="inherit-parent-width"
          maxWidth="max-width-767px"
          background="false"
          boxShadow="false"
          borderRadius="false"
          height="inherit-parent-height max-height-500px"
        >
          <div
            data-cy="cropper-modal"
            className={`background-white inherit-parent-height border-radius-default box-shadow-default font-family-RHD-regular font-color-secondary`}
          >
            <CropperModal
              className="border-radius-default"
              OnBlobCreate={(blob) => {
                const imageFile = new File([blob], `${+new Date()}.png`, {
                  type: "image/png",
                });
                setCroppedImage([...croppedImage, imageFile]);
              }}
              file={file && URL.createObjectURL(file)}
              setFile={() => {
                setFile(null);
              }}
            />
          </div>
        </Modal>
        <Modal
          show={showDataModal}
          onClose={() => {
            setShowDataModal(false);
            handleLogout();
          }}
          canIgnore={true}
          className="display-flex flex-justify-content-center"
          position="position-absolute bottom-0"
          boxShadow="none"
          borderRadius="none"
          maxWidth="max-width-767px"
          width="inherit-parent-width"
          background="background-transparent"
          height="height-fit-to-content"
        >
          <div
            data-cy="OTP-modal"
            className="inherit-parent-width inherit-parent-height padding-larger background-white"
          >
            <div className="text-align-center">
              {languageData.provideDetails}
            </div>
            <form
              onChange={(event) => {
                setFormData({
                  ...formData,
                  [event.target.name]: event.target.value,
                });
              }}
              onSubmit={(event) => {
                event.preventDefault();
                handleValidateOTP();
              }}
            >
              <InputBox
                name="fullName"
                type="text"
                optional={`(${languageData.optional})`}
                removeResponsive={true}
                label={languageData.name}
                isValidInput={(value) => {
                  setIsValidFullName(value);
                }}
                className="margin-top-large "
                value={formData.fullName}
              />
              <PhoneNumberBox
                name="phoneNumber"
                type="tel"
                countryInfoClickDisable={true}
                countryInfoClick={() => {
                  setCountryInfo({ ...countryInfo, show: true });
                }}
                selectedCountry={countryInfo.selected}
                removeResponsive={true}
                forceFocus={true}
                isValidInput={(value) => {
                  setIsValidPhoneNumber(value);
                }}
                buttonTile={
                  verificationId ? languageData.resendOTP : languageData.getOTP
                }
                buttonType={verificationId ? "button" : "submit"}
                label={languageData.phoneNumber}
                onButtonClick={() => {
                  if (formData.phoneNumber && isValidPhoneNumber) {
                    otpRequestHandler();
                  } else {
                    props.setStatus({
                      code: "error",
                      message: "Enter valid data",
                    });
                  }
                }}
                suffixButtonDisabled={
                  formData.phoneNumber?.length !== 10 && !loading
                }
                className="margin-top-large"
                value={formData.phoneNumber}
              />
              {verificationId && (
                <>
                  <OTPInputBox
                    name="otp"
                    label={languageData.enterOTP}
                    autoFocus={true}
                    value={formData.OTP}
                    setValue={(value) => {
                      setFormData({
                        ...formData,
                        OTP: value,
                      });
                    }}
                    required
                    disabled={true}
                    className="margin-bottom-large"
                  />
                </>
              )}
              <Button
                text={languageData.submit}
                boxShadow={false}
                data-cy={"submit-button"}
                type={verificationId ? "submit" : "button"}
                className="margin-top-default"
                disabled={handleDisable()}
                loading={loading || sendOTPLoading}
              />
            </form>
          </div>
        </Modal>
        <Modal
          show={
            !selectedLocationData || locationSearchModal
              ? true
              : false
          }
          className="display-flex flex-justify-content-center"
          position="position-absolute bottom-0"
          boxShadow="none"
          borderRadius="none"
          maxWidth="max-width-767px"
          width="inherit-parent-width"
          background="background-transparent"
          height="height-70-percentage"
        >
          <div
            data-cy="location-search-modal"
            className="inherit-parent-width inherit-parent-height padding-large background-white  "
          >
            <div className=" display-flex flex-justify-content-space-between flex-align-items-center">
              <div>Set current location</div>

              {selectedLocationData && (
                <div
                  onClick={() => {
                    setLocationSearchModal(false);
                  }}
                  data-cy={"search-location-modal-close-button"}
                >
                  <CloseIcon color="#404040" height={16} width={16} />
                </div>
              )}
            </div>
            <form
              autoComplete="off"
              onChange={(event) => {
                props.setSearchKey(event.target.value);
              }}
            >
              <InputBox
                name="search"
                type="text"
                removeResponsive={true}
                autoComplete="off"
                label="Search"
                className="padding-top-small"
                value={props.searchKey}
              />
            </form>

            <div className=" parent-height-sub-86px overflow-scroll">
              {isValidArray(props.locations) &&
                props.locations
                  ?.sort(
                    (a, b) =>
                      a.PCTNo.localeCompare(b.PCTNo, undefined, {
                        numeric: true,
                      }) -
                      b.PCTNo.localeCompare(a.PCTNo, undefined, {
                        numeric: true,
                      })
                  )
                  ?.map((data, index) => (
                    <div
                      className=" padding-top-large"
                      key={index}
                      onClick={() => {
                        window.location.pathname = `/${data.documentId}`;
                      }}
                      data-cy={data.location}
                    >
                      <div className=" display-flex flex-justify-content-space-between flex-align-items-center">
                        <div>
                          <div className=" font-family-RHD-medium font-size-medium">
                            {`${data.PCTNo} : ${data.location}`}
                          </div>
                          <div className=" font-size-small display-flex padding-top-smaller">
                            <div> {`Ward: ${data.ward}`} </div>
                            <div className=" padding-left-default">
                              {`Zone: ${data.zone}`}
                            </div>
                          </div>
                        </div>
                        <ArrowIcon />
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </Modal>

        <Modal
          show={showLanguageModal}
          canIgnore={true}
          onClose={() => {
            setShowLanguageModal(false);
          }}
          className="display-flex flex-justify-content-center"
          position="position-absolute bottom-0"
          boxShadow="none"
          borderRadius="none"
          maxWidth="max-width-767px"
          width="inherit-parent-width"
          background="background-transparent"
          height="height-15-percentage"
        >
          <div
            data-cy="language-modal"
            className="inherit-parent-width inherit-parent-height padding-large background-white  "
          >
            <div className="text-align-center">
              {languageData.chooseLanguage}
            </div>
            <div className="padding-top-large">
              {LanguagesList.map((data, index) => (
                <div
                  key={index}
                  data-cy={`${data.title}-card`}
                  onClick={() => {
                    if (selectedLanguage !== data.value) {
                      setSelectedLanguage(data.value);
                    }
                    setShowLanguageModal(false);
                  }}
                  className="padding-top-default padding-bottom-large font-size-default display-flex flex-justify-content-space-between"
                >
                  <div>{data.title}</div>
                  {selectedLanguage === data.value ? (
                    <div>
                      <TickIconWithCircle />
                    </div>
                  ) : (
                    <div>
                      <ArrowIcon />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Modal>

        <Modal
          show={countryInfo.show}
          canIgnore={true}
          onClose={() => {
            setCountryInfo({ ...countryInfo, show: false });
          }}
          className="display-flex flex-justify-content-center over-flow-hidden"
          position="position-absolute bottom-0"
          boxShadow="none"
          borderRadius="none"
          maxWidth="max-width-767px"
          width="inherit-parent-width"
          background="background-transparent"
          height="height-50-percentage"
        >
          <div
            data-cy="language-modal"
            className="inherit-parent-width inherit-parent-height padding-large background-white over-flow-hidden"
          >
            <div className="text-align-center">SELECT COUNTRY</div>
            <InputBox
              name="searchKey"
              type="text"
              removeResponsive={true}
              label={"CODE / COUNTRY"}
              className="margin-top-large"
              value={countryInfo.searchKey}
              onChange={(e) => {
                setCountryInfo({ ...countryInfo, searchKey: e.target.value });
              }}
            />
            <div className="padding-top-large remaining-height-for-country-code overflow-scroll">
              {countryData
                ?.filter((element) => {
                  if (countryInfo?.searchKey) {
                    if (/^[A-Za-z]+$/.test(countryInfo?.searchKey)) {
                      return element.name
                        .toLocaleUpperCase()
                        .includes(countryInfo?.searchKey?.toLocaleUpperCase());
                    } else {
                      return element.dial_code.includes(countryInfo?.searchKey);
                    }
                  } else {
                    return element;
                  }
                })
                .map((country, index) => (
                  <div
                    key={index}
                    data-cy={`${country.name}-card`}
                    onClick={() => {
                      if (
                        countryInfo?.selected?.dial_code === country.dial_code
                      ) {
                        setCountryInfo({ ...countryInfo, selected: null });
                      } else {
                        setCountryInfo({
                          ...countryInfo,
                          selected: country,
                          show: false,
                        });
                      }
                    }}
                    className="padding-top-default padding-bottom-large font-size-default display-flex flex-justify-content-space-between"
                  >
                    <div className="display-flex width-fit-content">
                      <div
                        style={{
                          width: "44px",
                        }}
                        className="margin-right-default"
                      >
                        {country.dial_code}
                      </div>
                      <div>{country.flag}</div>
                      <div className="padding-left-large">{country.name}</div>
                    </div>
                    {countryInfo?.selected?.dial_code === country.dial_code ? (
                      <div>
                        <TickIconWithCircle />
                      </div>
                    ) : (
                      <div>
                        <ArrowIcon />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </Modal>
      </div>

      <div id="recaptcha-placeholder"></div>
    </>
  );
}

// const AppLoader = () => {
//   return (
//     <>
//       <div className="height-inherit display-flex flex-direction-column jus width-inherit letter-spacing-large flex-justify-content-center flex-align-items-center">
//         <img
//           className="margin-bottom-larger"
//           src={AppLoaderIcon}
//           alt="fallback "
//           width="152px"
//         />
//         <div className="qr-loader " />
//       </div>
//     </>
//   );
// };

// function PageNotFoundFallBack() {
//   return (
//     <div className="inherit-parent-height inherit-parent-width display-flex flex-direction-column flex-justify-content-center flex-align-items-center padding-larger">
//       <div>
//         <img src={PageNotFoundFallBackImage} alt="No internet Connection" />
//       </div>
//       <div className="display-flex flex-direction-column flex-justify-content-center flex-align-items-center padding-top-large">
//         <div className="white-space-nowrap font-size-default font-family-RHD-medium">
//           Page not found
//         </div>
//         <div className="padding-top-default font-size-medium text-align-center">
//           Check your internet connection and try again later
//         </div>
//       </div>
//     </div>
//   );
// }

const LanguagesList = [
  { title: "English", value: "eng" },
  { title: "தமிழ்", value: "tam" },
];

function isValidString(str) {
  if (!str) {
    return false;
  }

  if (str.trim() === "") {
    return false;
  }

  if (/[^a-zA-Z0-9\s]/.test(str)) {
    return false;
  }

  return true;
}
